import React from 'react';
import { FieldError, UseFormRegister, Path } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { BookingFormValues } from '../pages/booking';

type Option = {
  label: React.ReactNode;
  value: string | number | string[];
  price?: number;
  sessionPrice?: number;
};

type SelectPropType = {
  register: UseFormRegister<BookingFormValues>;
  label: Path<BookingFormValues>;
  required: boolean;
  errors?: FieldError | undefined;
  title?: string;
  badgeType?: 'required' | 'optional';
  style?: { [key: string]: string };
  options: Option[];
  defaultValue?: string;
};

const BaseSelect = ({
  register,
  label,
  required,
  errors,
  title,
  badgeType,
  style,
  options,
  defaultValue,
}: SelectPropType) => {
  return (
    <>
      {!!title && (
        <p className="form-title">
          {!!badgeType && badgeType === 'required' ? (
            <span className="badge bg-danger">必須</span>
          ) : (
            <span className="badge bg-info">任意</span>
          )}
          &nbsp;{title}
        </p>
      )}
      <div className="form-group form-group-lg spacer30" style={{ flex: 1, ...style }}>
        <select
          className="form-control"
          {...register(label, { required })}
          defaultValue={defaultValue}
        >
          {React.Children.toArray(
            options.map(({ label, value }) => <option value={value}>{label}</option>),
          )}
        </select>
        {!!errors && errors.type === 'required' && (
          <>
            <div className="spacer10"></div>
            <div className="alert alert-danger">
              <span>必須です</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

const Select = styled(BaseSelect)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default React.memo(Select);
