import { init, send } from 'emailjs-com';
export const isValidEmail = (email: string) => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

export const sendEmail = async (name: string, email: string, message: string) => {
  if (!name || !email || !message) {
    return Promise.reject({
      message: 'メールアドレスやをご確認ください',
      isError: true
    });
  }

  const serviceId = process.env.EMAILJS_SERVICE_ID!;
  const templateId = process.env.EMAILJS_TEMPLATE_ID!;
  const userId = process.env.EMAILJS_USER_ID!;

  if (
    userId === undefined ||
    serviceId === undefined ||
    templateId === undefined
  ) return;

  init(userId);

  const templateParams = {
    name,
    email,
    message
  };
  try {
    const response = await send(serviceId, templateId, templateParams, userId);
    return Promise.resolve({
      message: response
    });
  } catch (error) {
    return Promise.reject({
      message: error,
      isError: true
    });
  }

}
