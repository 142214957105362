import React from 'react';
import { FieldError, UseFormRegister, Path } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { BookingFormValues } from '../pages/booking';

type InputPropType = {
  register: UseFormRegister<BookingFormValues>;
  label: Path<BookingFormValues>;
  required: boolean;
  errors?: FieldError | undefined;
  placeholder?: string;
  title?: string;
  badgeType?: 'required' | 'optional';
  style?: { [key: string]: string };
  defaultValue?: string;
  rules?: { [key: string]: string };
};

const BaseInput = ({
  register,
  label,
  required,
  errors,
  placeholder,
  title,
  badgeType,
  style,
  defaultValue,
  rules,
}: InputPropType) => {
  return (
    <>
      {!!title && (
        <p className="form-title">
          {!!badgeType && badgeType === 'required' ? (
            <span className="badge bg-danger">必須</span>
          ) : (
            <span className="badge bg-info">任意</span>
          )}
          &nbsp;{title}
        </p>
      )}
      <div className="form-group form-group-lg spacer30" style={{ flex: 1, ...style }}>
        <input
          className="form-control"
          {...register(label, { required, ...rules })}
          placeholder={placeholder}
          defaultValue={defaultValue}
        />
        {!!errors && errors.type === 'required' && (
          <div className="alert alert-danger" style={{ marginTop: '10px' }}>
            <span>必須です</span>
          </div>
        )}
        {!!errors && errors.type === 'pattern' && (
          <div className="alert alert-danger" style={{ marginTop: '10px' }}>
            <span>形式が異なります</span>
          </div>
        )}
      </div>
    </>
  );
};

const Input = styled(BaseInput)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default React.memo(Input);
