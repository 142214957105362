import React, { useState, useCallback } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import { StringParam, useQueryParam } from 'use-query-params';
import { Button } from '@mui/material';
import styled, { css } from 'styled-components';
import useBoolean from '../hooks/useBoolean';
import Layout from '../components/Layout';
import Input from '../components/Input';
import Select from '../components/Select';
import Modal from '../components/Modal';
import { sendEmail } from '../components/Email';
import { sendToSlack } from '../components/Slack';
import {
  ADDRESS_LIST,
  BROTHER_LIST,
  FAMILY_LIST,
  GENDER_LIST,
  SESISON_MONTH_LIST,
  SESISON_DAY_LIST,
  SESISON_TIME_LIST,
  AGE_LIST,
  SHICHIGOSAN_LIST,
  OMIYAMAIRI_LIST,
  ODEKAKE_LIST,
  REPEATER_LIST,
  WEDDING_LIST,
  SEIJINSHIKI_LIST,
} from '../const/const';

// import { DevTool } from '@hookform/devtools';

type BasePagePropsType = {
  className?: string;
};

export type BookingFormValues = {
  category: {
    title: string;
    value: string;
  };
  date1: {
    title: string;
    month: string;
    day: string;
    time: string;
    value: string;
  };
  date2: {
    title: string;
    month: string;
    day: string;
    time: string;
    value: string;
  };
  kid1: {
    title: string;
    age: string;
    gender: string;
    value: string;
  };
  kid2: {
    title: string;
    age: string;
    gender: string;
    value: string | undefined;
  };
  kid3: {
    title: string;
    age: string;
    gender: string;
    value: string | undefined;
  };
  plan: {
    title: string;
    value: string;
  };
  brother: {
    title: string;
    value: string;
  };
  address: {
    title: string;
    value: string;
  };
  family: {
    title: string;
    value: string;
  };
  odekake: {
    title: string;
    value: string;
  };
  repeater: {
    title: string;
    value: string;
  };
  email: {
    title: string;
    value: string;
  };
  name: {
    title: string;
    value: string;
  };
  tel: {
    title: string;
    value: string;
  };
  memo: {
    title: string;
    value: string;
  };
};

const getDate = () => {
  const today = new Date();
  const month = (today.getMonth() + 1).toString();
  const day = today.getDate().toString();
  return { month, day };
};

const BasePage: React.FC<BasePagePropsType> = ({ className }) => {
  const [category] = useQueryParam('category', StringParam);

  const getSessionList = () => {
    switch (category) {
      case 'birthday':
        return AGE_LIST;
      case 'entree':
        return AGE_LIST;
      case 'omiyamairi':
        return OMIYAMAIRI_LIST;
      case 'seijinshiki':
        return SEIJINSHIKI_LIST;
      case 'shichigosan':
        return SHICHIGOSAN_LIST;
      case 'wedding':
        return WEDDING_LIST;
      default:
        return [];
    }
  };

  const getKidAge = (index: number = 0) => {
    switch (category) {
      case 'birthday':
        return AGE_LIST[0].value as string;
      case 'entree':
        return AGE_LIST[0].value as string;
      case 'omiyamairi':
        return OMIYAMAIRI_LIST[0].value as string;
      case 'shichigosan':
        return SHICHIGOSAN_LIST[index].value as string;
      default:
        return '';
    }
  };

  const getPlan = () => {
    switch (category) {
      case 'seijinshiki':
        return SEIJINSHIKI_LIST[0].value as string;
      case 'wedding':
        return WEDDING_LIST[0].value as string;
      default:
        return '';
    }
  };

  const getTitle = () => {
    switch (category) {
      case 'birthday':
        return 'バースデー';
      case 'entree':
        return 'ご入園ご入学';
      case 'omiyamairi':
        return 'お宮参り';
      case 'seijinshiki':
        return '成人式';
      case 'shichigosan':
        return '七五三';
      case 'wedding':
        return 'フォト婚';
      default:
        return '記念写真撮影';
    }
  };

  const defaultValues: BookingFormValues = {
    category: {
      title: '撮影内容',
      value: getTitle(),
    },
    date1: {
      title: '第1希望日',
      month: getDate().month,
      day: getDate().day,
      time: '10',
      value: '',
    },
    date2: {
      title: '第2希望日',
      month: getDate().month,
      day: getDate().day,
      time: '10',
      value: '',
    },
    kid1: {
      title: category === 'seijinshiki' || category === 'wedding' ? '' : '撮影するお子様',
      age: category === 'seijinshiki' || category === 'wedding' ? '' : getKidAge(),
      gender:
        category === 'seijinshiki' || category === 'wedding' || category === 'shichigosan'
          ? ''
          : (GENDER_LIST[0].value as string),
      value: '',
    },
    kid2: {
      title: category === 'shichigosan' ? '撮影するお子様2人目' : '',
      age: category === 'shichigosan' ? getKidAge(4) : '',
      gender: category === 'shichigosan' ? (GENDER_LIST[0].value as string) : '',
      value: category === 'shichigosan' ? '' : undefined,
    },
    kid3: {
      title: category === 'shichigosan' ? '撮影するお子様3人目' : '',
      age: category === 'shichigosan' ? getKidAge(4) : '',
      gender: category === 'shichigosan' ? (GENDER_LIST[0].value as string) : '',
      value: category === 'shichigosan' ? '' : undefined,
    },
    plan: {
      title: category === 'seijinshiki' || category === 'wedding' ? '撮影プラン' : '',
      value: category === 'seijinshiki' || category === 'wedding' ? getPlan() : '',
    },
    brother: {
      title: category === 'seijinshiki' || category === 'wedding' ? '' : 'ごきょうだいと一緒に撮影',
      value:
        category === 'seijinshiki' || category === 'wedding'
          ? ''
          : (BROTHER_LIST[0].value as string),
    },
    family: {
      title: '家族写真',
      value: FAMILY_LIST[0].value as string,
    },
    odekake: {
      title:
        category === 'seijinshiki' || category === 'shichigosan' || category === 'omiyamairi'
          ? 'おでかけ'
          : '',
      value:
        category === 'seijinshiki' || category === 'shichigosan' || category === 'omiyamairi'
          ? (ODEKAKE_LIST[0].value as string)
          : '',
    },
    repeater: {
      title: '当店のご利用は',
      value: REPEATER_LIST[0].value as string,
    },
    address: {
      title: 'お住まい',
      value: ADDRESS_LIST[0].value as string,
    },
    name: { title: 'お名前', value: '' },
    email: { title: 'メールアドレス', value: '' },
    tel: { title: '電話番号', value: '' },
    memo: { title: '備考', value: '' },
  };

  const { state, setTrue: onOpenModal, setFalse: onCloseModal } = useBoolean(false);
  const [formData, setFormData] = useState('');
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const { state: loading, setTrue: setLoadingTrue, setFalse: setLoadingFalse } = useBoolean(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { register, formState, handleSubmit } = useForm<BookingFormValues>({
    defaultValues,
    mode: 'onBlur',
  });
  const { isValid, errors } = formState;

  const onSubmit = (data: BookingFormValues) => {
    const keys = Object.keys(data) as (keyof typeof data)[];
    let honbun = '';
    for (const key of keys) {
      if (key === 'date1' || key === 'date2')
        data[key].value = `${data[key].month}月${data[key].day}日${data[key].time}時`;
      if (category !== 'wedding' && category !== 'seijinshiki') {
        if (category !== 'shichigosan' && key === 'kid1')
          data[key].value = `${data[key].age} ${data[key].gender}`;
        if (category === 'shichigosan' && key === 'kid1') data[key].value = `${data[key].age}`;
        if (category === 'shichigosan' && (key === 'kid2' || key === 'kid3'))
          data[key].value = `${data[key].age}`;
      }
      if (key === 'name') setUserName(data[key].value);
      if (key === 'email') setEmail(data[key].value);

      if (!!data[key].value) {
        honbun += `${data[key].title}:${data[key].value}`;
        honbun += '\n';
      }
    }

    setFormData(honbun);
    onOpenModal();
  };

  const onSendHandler = useCallback(async () => {
    setErrorMessage('');
    setLoadingTrue();
    sendToSlack(formData);
    try {
      const response = await sendEmail(userName, email, formData);
      if (!!response && response.message.status === 200) {
        setLoadingFalse();
        onCloseModal();
        navigate('/booking-submitted/', {
          replace: true,
          state: { formData },
        });
      } else {
        setLoadingFalse();
        setErrorMessage('エラー\n送信できませんでした\n通信環境や入力内容をもう一度ご確認ください');
      }
    } catch (error) {
      setLoadingFalse();
      setErrorMessage('エラー\n送信できませんでした\n通信環境や入力内容をもう一度ご確認ください');
    }
  }, [formData]);

  return (
    <div className={className}>
      <Layout>
        <Modal
          open={state}
          data={formData}
          onClose={onCloseModal}
          onSend={onSendHandler}
          loading={loading}
          errorMessage={errorMessage}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <h2>{getTitle()} ネット予約</h2>
              <span>以下のフォームを入力してください</span>
              <div className="spacer30"></div>

              <p className="form-title">
                <span className="badge bg-danger">必須</span> 第1希望日
              </p>

              <div className="flex-wrapper-row">
                <Select
                  required={true}
                  label={'date1.month'}
                  errors={errors.date1?.month}
                  options={SESISON_MONTH_LIST}
                  style={{ marginRight: '10px' }}
                  register={register}
                  defaultValue={defaultValues.date1.month}
                />
                <Select
                  required={true}
                  label={'date1.day'}
                  errors={errors.date1?.day}
                  options={SESISON_DAY_LIST}
                  style={{ marginRight: '10px' }}
                  register={register}
                  defaultValue={defaultValues.date1.day}
                />
                <Select
                  required={true}
                  label={'date1.time'}
                  errors={errors.date1?.time}
                  options={SESISON_TIME_LIST}
                  register={register}
                  defaultValue={defaultValues.date1.time}
                />
              </div>

              <p className="form-title">
                <span className="badge bg-danger">必須</span> 第2希望日
              </p>

              <div className="flex-wrapper-row">
                <Select
                  required={true}
                  label={'date2.month'}
                  errors={errors.date2?.month}
                  options={SESISON_MONTH_LIST}
                  style={{ marginRight: '10px' }}
                  register={register}
                />
                <Select
                  required={true}
                  label={'date2.day'}
                  errors={errors.date2?.day}
                  options={SESISON_DAY_LIST}
                  style={{ marginRight: '10px' }}
                  register={register}
                />
                <Select
                  required={true}
                  label={'date2.time'}
                  errors={errors.date2?.time}
                  options={SESISON_TIME_LIST}
                  register={register}
                />
              </div>

              {(category === 'seijinshiki' || category === 'wedding') && (
                <>
                  <p className="form-title">
                    <span className="badge bg-danger">必須</span> 撮影プラン
                  </p>

                  <div className="flex-wrapper-row">
                    <Select
                      required={true}
                      label={'plan.value'}
                      errors={errors.plan?.value}
                      options={getSessionList()}
                      register={register}
                    />
                  </div>
                </>
              )}

              {category !== 'seijinshiki' && category !== 'wedding' && (
                <>
                  <p className="form-title">
                    <span className="badge bg-danger">必須</span> 撮影するお子様
                    {category === 'shichigosan' && ' (1人目)'}
                  </p>

                  <div className="flex-wrapper-row">
                    <Select
                      required={true}
                      label={'kid1.age'}
                      errors={errors.kid1?.age}
                      options={getSessionList()}
                      register={register}
                      style={
                        (category === 'entree' ||
                          category === 'birthday' ||
                          category === 'omiyamairi') && { marginRight: '10px' }
                      }
                    />

                    {(category === 'entree' ||
                      category === 'birthday' ||
                      category === 'omiyamairi') && (
                      <Select
                        required={true}
                        label={'kid1.gender'}
                        errors={errors.kid1?.gender}
                        options={GENDER_LIST}
                        register={register}
                      />
                    )}
                  </div>
                </>
              )}

              {category === 'shichigosan' && (
                <>
                  <Select
                    title={' 撮影するお子様 (2人目)'}
                    badgeType={'optional'}
                    required={true}
                    label={'kid2.age'}
                    errors={errors.kid2?.age}
                    options={getSessionList()}
                    register={register}
                  />
                  <Select
                    title={' 撮影するお子様 (3人目)'}
                    badgeType={'optional'}
                    required={true}
                    label={'kid3.age'}
                    errors={errors.kid3?.age}
                    options={getSessionList()}
                    register={register}
                  />
                </>
              )}

              {category !== 'seijinshiki' && category !== 'wedding' && (
                <Select
                  title={'ごきょうだいと一緒に撮影は？'}
                  badgeType={'required'}
                  required={true}
                  label={'brother.value'}
                  errors={errors.brother?.value}
                  options={BROTHER_LIST}
                  register={register}
                />
              )}

              <Select
                title={'ご家族と一緒に撮影は？'}
                badgeType={'required'}
                required={true}
                label={'family.value'}
                errors={errors.family?.value}
                options={FAMILY_LIST}
                register={register}
              />

              {(category === 'seijinshiki' ||
                category === 'shichigosan' ||
                category === 'omiyamairi') && (
                <Select
                  title={'おでかけレンタルは？'}
                  badgeType={'required'}
                  required={true}
                  label={'odekake.value'}
                  errors={errors.odekake?.value}
                  options={ODEKAKE_LIST}
                  register={register}
                />
              )}

              <Select
                title={'当店を利用したことは？'}
                badgeType={'required'}
                required={true}
                label={'repeater.value'}
                errors={errors.repeater?.value}
                options={REPEATER_LIST}
                register={register}
              />

              <Select
                title={'お住まいは？'}
                badgeType={'required'}
                required={true}
                label={'address.value'}
                errors={errors.address?.value}
                options={ADDRESS_LIST}
                register={register}
              />

              <Input
                title={'お名前'}
                badgeType={'required'}
                placeholder="(例) お名前"
                required={true}
                label={'name.value'}
                errors={errors.name?.value}
                register={register}
              />

              <Input
                title={'メールアドレス'}
                badgeType={'required'}
                placeholder="(例) hayakawasyasin@gmail.com"
                required={true}
                label={'email.value'}
                errors={errors.email?.value}
                register={register}
                rules={{
                  pattern:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                }}
              />

              <Input
                title={'電話番号'}
                badgeType={'required'}
                placeholder="(例) 09000000000 ( ー ハイフン不要)"
                required={true}
                label={'tel.value'}
                errors={errors.tel?.value}
                register={register}
              />

              <Input
                title={'備考'}
                badgeType={'optional'}
                placeholder="ご質問などあればどうぞ"
                required={false}
                label={'memo.value'}
                errors={errors.memo?.value}
                register={register}
              />

              <div className="spacer30"></div>
              <Button type="submit" variant="contained" size="large" disabled={!isValid}>
                次へ
              </Button>
              <div className="spacer30"></div>
              {/* <DevTool control={control} /> */}

              <div className="card">
                <div className="card-body">
                  <h5 style={{ marginTop: '30px', fontWeight: 600 }}>ご予約にあたって</h5>
                  <ul style={{ margin: '30px 0', fontSize: '14px' }}>
                    <li>ご予約後、自動返信メールが届きます</li>
                    <li>
                      もしメールが届いていない場合、迷惑メールフォルダに入っていることがありますのでご確認ください
                    </li>
                    <li>受付順で、こちらから折り返しご連絡をいたします</li>
                    <li>いただきましたメール情報は、お得な情報を配信するために利用します</li>
                  </ul>
                </div>
              </div>
              <div className="spacer30"></div>
            </div>
          </div>
        </form>
      </Layout>
    </div>
  );
};
const Page = styled(BasePage)`
  ${() => {
    return css`
      & {
        h2 {
          margin-bottom: 30px;
        }
        .spacer30 {
          margin-bottom: 30px;
        }
        .flex-wrapper {
          display: flex;
          flex-direction: column;
        }
        .flex-wrapper-row {
          display: flex;
          flex-direction: row;
        }
      }
    `;
  }}
`;
export default Page;

// const Page = styled(BasePage)`
//   ${() => {
//     return css`
//       & {
//         h2 {
//           margin-bottom: 30px;
//         }
//         .spacer30 {
//           margin-bottom: 30px;
//         }
//         .flex-wrapper {
//           display: flex;
//           flex-direction: column;
//         }
//         .flex-wrapper-row {
//           display: flex;
//           flex-direction: row;
//         }
//       }
//     `;
//   }}
// `;
// export default Page;
