import React from 'react';
import styled, { css } from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Modal as MuiModal, Button, CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '6px',
  boxShadow: 24,
  p: 4,
  outline: 0,
  whiteSpace: 'pre-line' as 'pre-line',
};

type ModalPropType = {
  open: boolean;
  data: string;
  onClose: () => void;
  onSend: () => void;
  loading: boolean;
  errorMessage?: string;
};

const BaseModal = ({ open, data, onClose, onSend, loading, errorMessage = '' }: ModalPropType) => {
  return (
    <MuiModal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          sx={{ mt: 2, mb: 4, fontWeight: 600 }}
        >
          この内容で送信します
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2, mb: 4, fontSize: '14px' }}>
          {data}
        </Typography>
        <Button type="button" variant="text" size="large" sx={{ mr: 2 }} onClick={onClose}>
          戻る
        </Button>
        <Button
          type="button"
          variant="contained"
          size="large"
          onClick={onSend}
          endIcon={
            !!loading ? (
              <CircularProgress style={{ color: 'white', width: '16px', height: '16px' }} />
            ) : (
              <SendIcon />
            )
          }
        >
          送信
        </Button>
        {errorMessage.length > 0 && (
          <div className="alert alert-danger" style={{ margin: '30px 0 20px', fontSize: '14px' }}>
            <span>{errorMessage}</span>
          </div>
        )}
      </Box>
    </MuiModal>
  );
};

const Modal = styled(BaseModal)`
  ${() => {
    return css`
      & {
        .spacer10 {
          margin-bottom: 10px;
        }
        .spacer30 {
          margin-bottom: 30px;
        }
      }
    `;
  }}
`;
export default React.memo(Modal);
